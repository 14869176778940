if (module.hot) {
  module.hot.accept();
}

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/scrollTrigger";
import Glide from '@glidejs/glide';

document.addEventListener('DOMContentLoaded', () => {
  gsap.registerPlugin(ScrollTrigger);

  (function () {
    const tl = gsap.timeline();

    window.addEventListener('DOMContentLoaded', function () {

      const addClass = () => {
        document.querySelector('.hero__image').classList.add('show-circles');
      }

      tl.from('.hero__image', { duration: 0.5, y: '20%', opacity: 0 })
        .to('.hero__text', { duration: 0.5, opacity: 1, delay: 0.3 })
        .call(addClass);
    });
  } ());

  (function () {

    const addClass = (element) => {

      if (NodeList.prototype.isPrototypeOf(element)) {

        element.forEach(item => item.classList.add('show'));
      } else {
        element.classList.add('show');
      }
    }

    gsap.from(document.getElementById('about-slideshow'), { duration: 1, y: 100, opacity: 0,
      scrollTrigger: {
        trigger: '#about-slideshow',
        start: 'top 75%'
      }
    });

    gsap.from(document.getElementById('mission'), { duration: 1, y: 100, opacity: 0,
      scrollTrigger: {
        trigger: '#mission',
        start: 'top 75%'
      }
    });

    gsap.from(document.getElementById('how-it-works'), { duration: 1, y: 100, opacity: 0,
      scrollTrigger: {
        trigger: '#how-it-works',
        start: 'top 75%'
      },
      onComplete: addClass.bind(null, document.querySelector('.how-it-works__image')),
    });

    gsap.from(document.getElementById('platform'), { duration: 1, y: 100, opacity: 0,
      scrollTrigger: {
        trigger: '#platform',
        start: 'top 75%'
      }
    });

    gsap.from(document.getElementById('vision'), { duration: 1, y: 100, opacity: 0,
      scrollTrigger: {
        trigger: '#vision',
        start: 'top 75%'
      },
      onComplete: addClass.bind(null, document.querySelectorAll('.vision__image'))
    });
  } ());

  function scrollTo(element) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.offsetTop - 100
    });
  }

  (function () {
    new Glide('#how-it-works-slideshow', {
      type: 'carousel'
    }).mount();
    new Glide('#about-slideshow', {
      type: 'carousel'
    }).mount();
  } ());

  (function () {
    const missionNavigationLinks = document.querySelectorAll('.js-mission-navigation-link');
    const tl = gsap.timeline();

    missionNavigationLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        if (tl.isActive() || e.target.classList.contains('mission-navigation__link--active')) {
          return null;
        }

        missionNavigationLinks.forEach(link => {
          link.classList.remove('mission-navigation__link--active');
        });

        e.target.classList.add('mission-navigation__link--active');

        const id = e.target.getAttribute('data-id');

        const element = document.getElementById(id);
        const elementNumberDiv = element.querySelector('.mission-content__number div');
        const elementNumberSpan = element.querySelector('.mission-content__number span');

        const active = document.querySelector('.mission-content__point--active');
        const activeNumberDiv = active.querySelector('.mission-content__number div');
        const activeNumberSpan = active.querySelector('.mission-content__number span');

        const switchClasses = () => {
          active.classList.remove('mission-content__point--active');
          element.classList.add('mission-content__point--active');
          tl.clear();
        }

        tl.to(active, { duration: 0.5, opacity: 0, y: -200, display: 'none' })
          .to(element, { duration: 0.5, opacity: 1, y: 0, display: 'block' })
          .set(active, { y: 200 })
          .set(activeNumberDiv, { width: 0, height: 0 })
          .set(activeNumberSpan, { opacity: 0 })
          .to(elementNumberDiv, { duration: 0.3, width: 120, height: 120 })
          .to(elementNumberSpan, { duration: 0.3, opacity: 1 })
          .call(switchClasses);
      })
    })
  } ());

  (function () {
    const navigation = document.getElementById('navigation');
    const navTrigger = document.getElementById('navigation-trigger');
    const tl = gsap.timeline({ paused: true, reversed: true });
    const navigationLinks = document.querySelectorAll('.js-navigation-link');

    navTrigger.addEventListener('click', (e) => {
      e.preventDefault(e);

      e.target.classList.toggle('active');

      tl.to(navigation, { duration: 0.5, width: 250, height: 250, x: 0, y: 0, paddingTop: '80px', paddingRight: '20px' })
        .to('.navigation__list', { duration: 0.5, opacity: 1, y: 0 });

      tl.reversed() ? tl.play() : tl.reverse();
    });

    navigationLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        const id = e.target.getAttribute('href');

        scrollTo(document.querySelector(id));

        tl.reverse();
        navTrigger.classList.remove('active');
      })
    })
  } ());
});
